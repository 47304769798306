import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HttpStatusCode } from 'axios';
import { omitBy, isNil, join, compact, isEmpty } from 'lodash';
import { errorToastify, successToastify } from 'helpers/toast';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SanitySearchDropdown from 'pages/Admin/SanitySearchDropdown';
import { MODULES } from 'pages/Constants';
import { addOngoingChapter, getRecentOngoingChapter } from 'helpers/classes';
import { getClassDetailsRedux } from 'slices/admin/class/reducer';
import { toast } from 'react-toastify';
import { datadogLogs } from '@datadog/browser-logs';
import { listOfSanitySubjectsService } from 'helpers/subject';

export const getFullName = (fName, lName) => {
  return join(compact([fName, lName]), ' ');
};

export const OngoingChapter = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedChapters, setSelectedChapters] = useState({});
  const [submittedChapters, setSubmittedChapters] = useState({});

  const [allChaptersFetched, setAllChaptersFetched] = useState(false);
  const classDetailsData = useSelector(getClassDetailsRedux);
  const [subjects, setSubjects] = useState([]);
  const toastId = '3hdyr8fd8';

  const handleChapterSelect = (schoolId, subjectId, chapters) => {
    setSelectedChapters((prev) => ({
      ...prev,
      [`${schoolId}_${subjectId}`]: chapters,
    }));
  };

  useEffect(() => {
    async function fetchSubjects() {
      try {
        const result = await listOfSanitySubjectsService();
        setSubjects(result?.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchSubjects();
  }, []);

  const submitOngoingChapter = async () => {
    // let successfulSubmissions = 0;
    // let totalSchools = classDetailsData?.schoolDetails?.length || 0;

    try {
      for (const subject of subjects) {
        for (const school of classDetailsData?.schoolDetails || []) {
          const chapters = selectedChapters[`${school?.id}_${subject?._id}`];

          if (isNil(chapters) || isEmpty(chapters)) {
            errorToastify(
              `No chapter selected for ${school?.name} , ${subject?.name}`,
            );
            continue;
          }

          try {
            for (const chapter of chapters) {
              if (
                !submittedChapters?.[`${school?.id}_${subject?._id}`]?.some(
                  (chapterId) => chapterId === chapter?.id,
                )
              ) {
                const payload = {
                  schoolId: school.id,
                  schoolName: school.name,
                  chapterId: chapter?.id,
                  chapterName: chapter?.name,
                  subjectId: chapter?.subjectId,
                  subjectName: chapter?.subjectName,
                  batchId: classDetailsData?.batchDetails?.id,
                  boardId: classDetailsData?.batchDetails?.boardId,
                  boardName: classDetailsData?.batchDetails?.boardName,
                  gradeId: classDetailsData?.batchDetails?.gradeId,
                  gradeName: classDetailsData?.batchDetails?.gradeName,
                };

                try {
                  await addOngoingChapter(payload);
                } catch (error) {
                  console.log(error?.message);

                  datadogLogs?.logger?.error({
                    message: `Unable to add ongoing chapter for schoolId -> ${school?.id}, subject -> ${subject?.name} chapterId -> ${chapter?.id}, chapterName -> ${chapter?.name}`,
                  });
                }
              }
            }

            // successfulSubmissions++;

            successToastify(
              `Successfully added ongoing chapters for ${school.name}`,
            );
          } catch (err) {
            errorToastify(
              `Failed to add ongoing chapters for ${school.name}: ${err?.message}`,
            );

            datadogLogs?.logger?.error({
              message: `Failed to add ongoing chapters for -> ${school.name}: ${err?.message}`,
            });
          }
        }
      }
    } catch (error) {
      errorToastify('An error occurred while submitting ongoing chapters');
    }

    setShowModal(false);
  };

  useEffect(() => {
    const fetchDataForSchools = async () => {
      let chaptersFetched = 0;

      if (classDetailsData && classDetailsData.schoolDetails) {
        for (const subject of subjects) {
          for (const school of classDetailsData.schoolDetails) {
            try {
              const params = omitBy(
                {
                  schoolId: school?.id,
                  gradeId: classDetailsData?.batchDetails?.gradeId,
                  boardId: classDetailsData?.batchDetails?.boardId,
                  batchId: classDetailsData?.batchDetails?.id,
                  subjectId: subject?._id,
                },
                isNil,
              );
              const recentChapterResponse =
                await getRecentOngoingChapter(params);

              datadogLogs?.logger?.info(
                `Got recent on-going chapter data for schoolId - ${school?.id}, batchId - ${classDetailsData?.batchDetails?.id}`,
                recentChapterResponse,
              );

              if (recentChapterResponse?.data) {
                chaptersFetched++;

                const chapterIds = recentChapterResponse?.data?.map(
                  (chapter) => chapter?.chapterId,
                );

                setSubmittedChapters((prev) => ({
                  ...prev,
                  [`${school?.id}_${subject?._id}`]: chapterIds,
                }));
              }
            } catch (error) {
              if (error?.status === HttpStatusCode.NotFound) {
                datadogLogs?.logger?.info(
                  `Showing on going chapter nudge for schoolId - ${school?.id}, batchId - ${classDetailsData?.batchDetails?.id}`,
                );

                if (!toast.isActive(toastId)) {
                  toast.success(
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="text-dark">Add ongoing chapters</span>
                      <button
                        onClick={() => {
                          setShowModal(true);
                          toast.dismiss();
                        }}
                        className="btn btn-sm bg-success mx-2 text-light"
                      >
                        Add
                      </button>
                      <button
                        onClick={() => toast.dismiss()}
                        className="btn btn-sm bg-danger text-light"
                      >
                        Later
                      </button>
                    </div>,
                    {
                      autoClose: false,
                      icon: false,
                      toastId,
                      closeOnClick: false,
                    },
                  );
                }

                break;
              }
            }
          }
        }

        if (chaptersFetched === 2 * classDetailsData.schoolDetails.length) {
          setAllChaptersFetched(true);
        }
      }
    };

    if (!allChaptersFetched) {
      fetchDataForSchools();
    }

    const intervalId = setInterval(
      () => {
        if (!allChaptersFetched) {
          fetchDataForSchools();
        }
      },
      30 * 60 * 1000, // 30 mins
    );

    if (allChaptersFetched) clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [classDetailsData, allChaptersFetched, subjects]);

  return (
    <Modal isOpen={showModal} size="lg" toggle={() => setShowModal(!showModal)}>
      <ModalHeader toggle={() => setShowModal(!showModal)}>
        Ongoing Chapters
      </ModalHeader>
      <ModalBody>
        {classDetailsData?.schoolDetails?.map((school) => (
          <div key={school?.id} className="mb-4">
            <h5 className="font-weight-bold">
              Select Ongoing Chapter for School: {school.name}
            </h5>
            <p className="text-muted">
              <strong>Students:</strong>{' '}
              {classDetailsData?.studentDetails
                ?.filter(({ schoolId }) => schoolId === school?.id)
                ?.map(({ firstName, lastName }) =>
                  getFullName(firstName, lastName),
                )
                ?.join(', ')}
            </p>

            {subjects?.map((subject) => (
              <SanitySearchDropdown
                key={subject?._id}
                model={MODULES.CHAPTERS}
                additionalFilter={{
                  'grade._ref': classDetailsData?.batchDetails?.gradeId,
                  'subject._ref': subject?._id,
                  'board._ref': classDetailsData?.batchDetails?.boardId,
                  'book._ref': classDetailsData?.batchDetails?.bookIds
                    ?.filter((book) => book?.subjectId === subject?._id)
                    ?.map((book) => book?.bookId),
                }}
                defaultValue={
                  selectedChapters?.[`${school.id}_${subject?._id}`]?.map(
                    (chapter) => chapter.id,
                  ) ?? []
                }
                onChange={(result) => {
                  handleChapterSelect(
                    school.id,
                    subject?._id,
                    result.map((option) => ({
                      id: option.value,
                      name: option.name,
                      subjectId: subject?._id,
                      subjectName: subject?.name,
                    })),
                  );
                }}
                placeholder={`Select ${subject?.name} chapters`}
                multiSelect={true}
              />
            ))}
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submitOngoingChapter}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
