import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sanitySearchService } from 'helpers/common';
import { debounce, find, get, isEmpty } from 'lodash';
import {
  EPIC_SANITY_SEARCH_HELPER,
  SELECT_CUSTOM_STYLES,
} from 'pages/Constants';

const SanitySearchDropdown = ({
  model,
  onChange,
  multiSelect = false,
  defaultValue = null,
  placeholder = 'Search',
  additionalFilter = {},
  reset = false,
  ...props
}) => {
  const {
    displayKey = 'name',
    valueKey = '_id',
    labelRender,
  } = EPIC_SANITY_SEARCH_HELPER[model] || {};

  const getIsDefaultValue = useCallback(() => {
    if (Array.isArray(defaultValue)) return !isEmpty(defaultValue);
    return defaultValue;
  }, [defaultValue]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);

  const loadOptions = useCallback(
    (inputValue, callback) => {
      setIsLoading(true);
      const query = { model };
      if (getIsDefaultValue()) {
        query._id = defaultValue;
      }
      if (inputValue) {
        query.q = inputValue;
      }
      sanitySearchService({ ...query, ...additionalFilter })
        .then((resp) => {
          const data = get(resp, 'data', { docs: [] });
          const options = data.docs.map((result) => ({
            ...result,
            label: labelRender ? labelRender(result) : result[displayKey],
            value: result[valueKey],
          }));

          callback(options);
          if (query._id) {
            let filteredOptions;
            if (!multiSelect) {
              filteredOptions = find(options, { [valueKey]: query._id });
            } else {
              filteredOptions = query._id.map((id) => {
                return find(options, { [valueKey]: id });
              });
            }
            setSelectedOption(filteredOptions);
          } else {
            setSelectedOption(null);
          }
        })
        .catch((err) => {
          toast.error(err?.message || 'Error fetching data');
          callback([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      model,
      defaultValue,
      additionalFilter,
      getIsDefaultValue,
      multiSelect,
      valueKey,
      displayKey,
      labelRender,
    ],
  );

  const debouncedLoadOptions = React.useMemo(
    () =>
      debounce((inputValue, callback) => {
        loadOptions(inputValue, callback);
      }, 200),
    [loadOptions],
  );

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption || []);

    onChange && onChange(selectedOption);
  };

  useEffect(() => {
    setSelectedOption(null);
  }, [reset]);

  return (
    <div className="p-1">
      <AsyncSelect
        {...props}
        key={JSON.stringify({ additionalFilter, defaultValue })}
        cacheOptions
        defaultOptions
        loadOptions={debouncedLoadOptions}
        onChange={handleSelectChange}
        isLoading={isLoading}
        value={selectedOption}
        isClearable
        placeholder={placeholder}
        isMulti={multiSelect}
        styles={SELECT_CUSTOM_STYLES}
      />
    </div>
  );
};

export default SanitySearchDropdown;
