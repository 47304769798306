import { getFullName } from 'pages/DyteMeetings/Components/component/OngoingChapter';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRolesList } from 'slices/utils/reducer';
import { getUserDetails } from 'slices/admin/auth/reducer';
import { isEmpty } from 'lodash';

const getMediaDevices = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.error("Media Devices API not supported in this browser");
        return Promise.reject(new Error("Media Devices API not supported"));
    }

    return navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(stream => {
            return navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    const cameras = [];
                    const microphones = [];
                    const speakers = [];

                    devices.forEach(device => {
                        if (device.kind === 'videoinput') {
                            cameras.push(device.label || `Camera ${cameras.length + 1}`);
                        } else if (device.kind === 'audioinput') {
                            microphones.push(device.label || `Microphone ${microphones.length + 1}`);
                        } else if (device.kind === 'audiooutput') {
                            speakers.push(device.label || `Speaker ${speakers.length + 1}`);
                        }
                    });

                    stream.getTracks().forEach(track => track.stop());

                    const defaultSpeaker = speakers[0] || "No Active Speaker Source";
                    return {
                        cameras,
                        microphones,
                        speakers,
                        activeCamera: cameras[0] || "No Active Camera Source",
                        activeMicrophone: microphones[0] || "No Active Mic Source",
                        activeSpeaker: defaultSpeaker
                    };
                });
        })
        .catch(error => {
            console.error("Error accessing media devices:", error);
            return {
                cameras: [],
                microphones: [],
                speakers: [],
                activeCamera: "No Active Camera Source",
                activeMicrophone: "No Active Mic Source",
                activeSpeaker: "No Active Speaker Source"
            };
        });
};

const ZohoSalesIQ = () => {
    const userDetails = useSelector(getUserDetails);
    const getRolesListRedux = useSelector(getRolesList);

    const userData = useMemo(() => {
        const roleId = userDetails?.roleId;
        const userRole = getRolesListRedux?.find((role) => role.id === roleId);
        return {
            role: userRole ? userRole.name : '',
            name: userDetails ? getFullName(userDetails.firstName, userDetails.lastName) : '',
            email: userDetails?.email || '',
            contactNumber: userDetails?.mobileNumber || ''
        };
    }, [userDetails, getRolesListRedux]);

    useEffect(() => {
        const { name, email, contactNumber, role } = userData;

        if (isEmpty(userDetails) || !name || !email || !contactNumber || !role) {
            console.error('Required user details or role information are missing.');
            return;
        }

        const initializeZoho = async () => {
            try {
                const sources = await getMediaDevices();
                window.$zoho = window.$zoho || {};
                window.$zoho.salesiq = window.$zoho.salesiq || {};
                window.$zoho.salesiq.ready = function() {
                    try {
                        window.$zoho.salesiq.visitor.name(name);
                        window.$zoho.salesiq.visitor.email(email);
                        window.$zoho.salesiq.visitor.contactnumber(contactNumber.toString());

                        const additionalInfo = {
                            role: role,
                            activeCamera: sources.activeCamera,
                            activeSpeaker: sources.activeSpeaker,
                            activeMicrophone: sources.activeMicrophone
                        };

                        window.$zoho.salesiq.visitor.info(additionalInfo);
                    } catch (error) {
                        console.error('Error setting Zoho visitor info:', error);
                    }
                };

                const widgetScript = document.createElement('script');
                widgetScript.id = 'zsiqscript';
                widgetScript.src = process.env.REACT_APP_ZOHO_SRC_URL;
                widgetScript.defer = true;
                document.body.appendChild(widgetScript);

                return () => {
                    if (document.body.contains(widgetScript)) {
                        document.body.removeChild(widgetScript);
                    }
                    delete window.$zoho;
                };
            } catch (error) {
                console.error('Error setting up Zoho SalesIQ:', error);
            }
        };

        initializeZoho();
    }, [userDetails, userData]);

    return null;
};

export default ZohoSalesIQ;